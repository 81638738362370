import React, { useEffect, useState } from "react";
import { ESTATUS_FINAL } from "../../../constants/cons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startPostConcluirServicio } from "../../../context/actions/detalleActions";

export const useBotonCancelar = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();

  const {
    asistencia: { costo, folioGnp, esGnpApi },
    proveedorCostos: { lp1m, lp2m },
  } = useSelector((state) => state.detalleReducer);

  const options = [
    { label: "Con Costo", value: ESTATUS_FINAL.canceladoConCosto },
    { label: "Sin Costo", value: ESTATUS_FINAL.canceladoSinCosto },
  ];

  const { usuarioID } = useSelector((state) => state.userReducer.user);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ costo, estatusFinal: 4 });

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      costo,
    });
  }, [costo]);

  const handleOk = () => {
    let costoFinal = data.costo;

    if(data.estatusFinal === ESTATUS_FINAL.canceladoSinCosto)
        costoFinal=0

    const body = {
      ...data,
      costo: costoFinal,
      usuarioID,
      asistenciaID,
      folioGnp,
      esGnpApi,
    };

    setData({});
    setOpen(false);

    console.log(body);
    dispatch(startPostConcluirServicio(body));
  };

  const { estatusFinalID } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  const handleMotivo = (motivoCancelacion) =>
    setData({ ...data, motivoCancelacion });

  ///
  const isDisabled = () => {
    if (data.estatusFinal === ESTATUS_FINAL.canceladoConCosto) {
      if (!data.costo || data.costo === 0) return true;
    }

    if (!data.motivoCancelacion) return true;
    else if (data.motivoCancelacion === "Otro" && !data.comentarios)
      return true;

    return false;
  };

  return {
    options,
    open,
    setOpen,
    handleOk,
    isDisabled,
    estatusFinalID,
    data,
    handleChange,
    handleMotivo,
    lp1m,
    lp2m,
  };
};
