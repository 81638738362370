import { Col, Row } from "antd";
import React from "react";
import { dateFromString } from "../../../utils/dateFromString";

export const ServicioItem = ({ servicio }) => {
  const go = () =>
    window.open(`/detalleAsistencia/${servicio.asistenciaID}`, "_blank ");

  //
  return (
    <div className="TableRow">
      <Row onClick={go}>
        <Col xs={8} xxl={2}>
          {servicio.asistenciaID}
        </Col>
        <Col xs={8} xxl={3}>
          {dateFromString(servicio.fechaCapturaTerminada).dateStep}
        </Col>
        <Col xs={8} xxl={3}>
          {servicio.servicio}
        </Col>
        <Col xs={24} xxl={12}>
          {servicio.ubicacion}
        </Col>
      </Row>
    </div>
  );
};
