import React, { useEffect } from "react";
import CardPage from "../../../layout/CardPage";
import { Button, Col, Row } from "antd";
import { useGetServicios } from "./useGetServicios";
import { ServicioItem } from "./ServicioItem";
import { useGetSemaforoUnaVez } from "../useGetSemaforoUnaVez";

export const SemaforoEscalamiento = () => {
  const { serviciosEscalamiento } = useGetServicios();

  const { fetchData } = useGetSemaforoUnaVez();

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [fetchData]);

  return (
    <CardPage titulo="Semáforo Escalamiento de Asignacón">
      <Row>
        <Col span={4}>
          <div className="subTitulo">{serviciosEscalamiento.length} casos</div>
        </Col>
        <Col span={4}>
          <Button size="small">Recargar datos</Button>
        </Col>
      </Row>

      <Row className="TituloTabla">
        <Col xs={8} xxl={2}>
          ID
        </Col>
        <Col xs={8} xxl={3}>
          Llamada termianda
        </Col>
        <Col xs={8} xxl={3}>
          Servicio
        </Col>
        <Col xs={8} xxl={12}>
          Ubicacion
        </Col>
      </Row>

      {serviciosEscalamiento.map((s, i) => (
        <ServicioItem key={s.asistenciaID} servicio={s} />
      ))}
    </CardPage>
  );
};
