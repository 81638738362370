import React from "react";
import { useHistory } from "react-router-dom";
import CardPage from "../../layout/CardPage";
import { Col, Row } from "antd";
import { useGetSemaforoUnaVez } from "./useGetSemaforoUnaVez";


export const SemaforoHome = () => {
  const history = useHistory();
  useGetSemaforoUnaVez()

  return (
    <CardPage titulo="Semáforos">
      <Row gutter={[10, 10]}>
        <Col xs={12} xxl={6}>
          <div
            className="botonRecursos"
            onClick={() => history.push("/semaforo")}
          >
            Semáforo General
          </div>
        </Col>
        <Col xs={12} xxl={6}>
          <div
            className="botonRecursos"
            onClick={() => history.push("/semaforoSeguimiento")}
          >
            Semáforo Seguimiento
          </div>
        </Col>
        <Col xs={12} xxl={6}>
          <div
            className="botonRecursos"
            onClick={() => history.push("/semaforoEscalamiento")}
          >
            Semáforo Escalamiento
          </div>
        </Col>
      </Row>
    </CardPage>
  );
};
