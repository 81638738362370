import React from "react";
import { useSelector } from "react-redux";
import { RenglonSemaforo } from "../RenglonSemaforo";
import { TituloSemaforo } from "../TituloSemaforo";
import { getMinutesTotal } from "../../../../utils/getMinutesTotal";

export const PorContactarUsuario = () => {
  const { asistenciasSemaforo } = useSelector((state) => state.semaforoReducer);


  const asistencias = asistenciasSemaforo.filter(
    (a) => a.estatusID === 3 || a.estatusID === 4
  );

  const asitenciasOrdenadasPorFechaLLegada = [...asistencias].sort((a, b) => {
    return (
      getMinutesTotal(b.fechaTiempoLlegada) -
      getMinutesTotal(a.fechaTiempoLlegada)
    );
  });

  return (
    <>
      <TituloSemaforo
        titulo="Por Contactar Usuario"
        count={asitenciasOrdenadasPorFechaLLegada.length}
      />
      {asitenciasOrdenadasPorFechaLLegada.map((a, i) => {
        return (
          <RenglonSemaforo
            key={i}
            asistencia={a}
            tipo="contactar"
            a={a}
            fecha={a.fechaAsignacion}
          />
        );
      })}
    </>
  );
};
