import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  startGetAsistenciasUltimos7Dias,
} from "../../context/actions/asistenciasActions";
import CardPage from "../../layout/CardPage";
import { Listado } from "./Listado";

export const TodosServicios = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetAsistenciasUltimos7Dias());
  }, [dispatch]);

  return (
    <CardPage titulo="Servicios">
      <Listado />
    </CardPage>
  );
};
