import { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, DatePicker, Popconfirm } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  startSetEscalamientoAsignacion,
  startUpdateFecha,
} from "../../../context/actions/detalleActions";
import { dateFromString } from "../../../utils/dateFromString";
import { useDispatch } from "react-redux";

export const Asignado = ({
  asistencia: { estatusID, fechaAsignacion, fechaEscalamientoAsignacion },
}) => {
  ////////////////////////////
  const dispatch = useDispatch();

  const date = dateFromString(fechaAsignacion);
  const { asistenciaID } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [fecha, setFecha] = useState("");

  const handleModalOK = () => {
    const body = {
      fecha,
      asistenciaID,
      tipo: 7, // Asignacion
    };
    dispatch(startUpdateFecha(body));

    setShowModal(false);
  };

  const onDateChange = (momentTime, stringTime) => setFecha(stringTime);

  const submitEscalar = () =>
    dispatch(startSetEscalamientoAsignacion({ asistenciaID }));

  if (estatusID > 2)
    return (
      <div onDoubleClick={() => setShowModal(true)}>
        <ClockCircleOutlined /> {date.dateStep}
        <Modal
          title="Cambiar Fecha Asignación"
          open={showModal}
          onOk={handleModalOK}
          onCancel={() => setShowModal(false)}
        >
          <DatePicker showTime onChange={onDateChange} />
        </Modal>
      </div>
    );

  ////////////////////////////
  return (
    <div>
      {!fechaEscalamientoAsignacion ? (
        <Popconfirm
          title="¿Estas seguro de escalar el servicio?"
          onConfirm={submitEscalar}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <div className="marcarStep" style={{ color: "red" }}>
            <ExclamationCircleOutlined /> Escalar !
          </div>
        </Popconfirm>
      ) : (
        <div className="marcarStep" style={{ color: "green" }}>
          <CheckCircleOutlined /> Escalado
        </div>
      )}
    </div>
  );
};
