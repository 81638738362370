import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const useGetServicios = () => {
  const { asistenciasSemaforo } = useSelector((state) => state.semaforoReducer);
  const [serviciosEscalamiento, setServiciosEscalamiento] = React.useState([]);

  useEffect(() => {
    if (asistenciasSemaforo) {
      const servicios = asistenciasSemaforo.filter(
        (servicio) => servicio.estatusID === 2 && servicio.fechaEscalamientoAsignacion
      );

      setServiciosEscalamiento(servicios);
    }
  }, [asistenciasSemaforo]);
  return { serviciosEscalamiento };
};
