import { Col, Input, Row } from "antd";
import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { startGetAsistenciasByText } from "../../context/actions/asistenciasActions";

export const Controles = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  const handleChange = ({ target }) => {
    setText(target.value);
    if (target.value.length > 4) {
      dispatch(startGetAsistenciasByText(target.value));
    }
  };

  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={4}>
        <Input value={text} placeholder="buscar..." onChange={handleChange} />
      </Col>
    </Row>
  );
};
