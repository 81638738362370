import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  startGetAsistenciasCitas,
  startGetServicioParaSemaforo,
} from "../../context/actions/semaforoActions";

export const useGetSemaforoUnaVez = () => {
  const dispatch = useDispatch();

const fetchData = () => {
  dispatch(startGetServicioParaSemaforo());
  dispatch(startGetAsistenciasCitas());
}

  useEffect(() => {
    dispatch(startGetServicioParaSemaforo());
    dispatch(startGetAsistenciasCitas());
  }, [dispatch]);

  return { fetchData };
};
