import { Input } from "antd";
import React from "react";

export default function LabelNumber({
  label,
  name,
  onChange,
  value,
  placeholder,
  disabled,
  defaultValue
}) {
  return (
    <>
      <div className="inputLabel">
        {label}

      </div>
      <Input
        type="number"
        disabled={disabled}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />

    </>
  );
}
