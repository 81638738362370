import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Modal, Radio, Row, Select, Space } from "antd";
import LabelText from "../../../components/input/LabelText";
import LabelTextarea from "../../../components/input/LabelTextarea";

import { formatCurrency } from "../../../utils/formatCurrency";
import { useBotonCancelar } from "./useBotonCancelar";
import {
  ESTATUS_FINAL,
  MotivosCancelacionOptions,
} from "../../../constants/cons";

///
export const BotonCancelar = () => {
  const {
    options,
    open,
    setOpen,
    handleOk,
    isDisabled,
    estatusFinalID,
    data,
    handleChange,
    handleMotivo,
    lp1m,
    lp2m,
  } = useBotonCancelar();

  ///
  if (estatusFinalID > 1) return <></>;

  return (
    <>
      <div onClick={() => setOpen(true)} className="botonCancelar">
        Cancelar
      </div>

      <Modal
        title={<div style={{ color: "red" }}>Cancelar Servicio</div>}
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button onClick={() => setOpen(false)}>No Cancear</Button>,
          <Button onClick={handleOk} danger disabled={isDisabled()}>
            Cancear Servicio
          </Button>,
        ]}
      >
        <Row>
          <Col
            span={6}
            style={{ color: "red", textAlign: "center", paddingTop: 10 }}
          >
            <CloseCircleOutlined style={{ fontSize: 60 }} />
          </Col>
          <Col span={18} style={{ paddingTop: 5 }}>
            <div style={{ marginBottom: 20 }}>
              <Radio.Group
                name="estatusFinal"
                value={data.estatusFinal}
                options={options}
                onChange={handleChange}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            {`LP1m: ${formatCurrency(lp1m)} - LP2m: ${formatCurrency(lp2m)}`}
          </Col>
        </Row>

        <Divider />
        {ESTATUS_FINAL.canceladoSinCosto !== data.estatusFinal && (
          <div>
            <LabelText
              label="Costo final"
              name="costo"
              onChange={handleChange}
              value={data.costo}
            />
          </div>
        )}
        <div style={{ marginTop: 10 }}>
          <div className="inputLabel">Motivo Cancelación</div>
          <Select
            style={{ width: "100%" }}
            name="motivoCancelacion"
            onChange={handleMotivo}
            value={data.motivoCancelacion}
            options={MotivosCancelacionOptions}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <LabelTextarea
            value={data.comentarios}
            onChange={handleChange}
            label="Comentarios de cancelación"
            name="comentarios"
          />
        </div>
      </Modal>
    </>
  );
};
