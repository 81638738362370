import { Select } from "antd";

const { Option } = Select;

export default function LabelSelect({
  opciones,
  label,
  onChange,
  value,
  name,
  disabled,
}) {
  return (
    <>
      <div className="inputLabel">{label}</div>
      <Select
        disabled={disabled}
        showSearch
        value={value}
        name={name}
        onChange={onChange}
        style={{ width: "100%" }}
        filterOption={(input, option) => {
          return (
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
      >
        {opciones.map(({ texto, valor }, i) => {
          return (
            <Option key={i} value={valor}>
              {texto}
            </Option>
          );
        })}
      </Select>
    </>
  );
}
