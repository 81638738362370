import { Button, Col, Row } from "antd";
import React from "react";
import { FormaActividad } from "./_FormaActividad";
import { FormaIncidencia } from "./_FormaIncidencia";
import { LoginOutlined } from "@ant-design/icons";

export const Controles = ({ servicio }) => {

  const go = () =>
    window.open(`/detalleAsistencia/${servicio.asistenciaID}`, "_blank ");

  return (
    <Row gutter={[10,10]} style={{marginTop: 20}}>
      <Col xs={24} xxl={11}>
        <FormaActividad servicio={servicio} />
      </Col>
      <Col xs={24} xxl={11}>
        <FormaIncidencia servicio={servicio} />
      </Col>
      <Col><Button onClick={go} icon={<LoginOutlined />} type="link" danger size="small">Abrir Servicio</Button></Col>
    </Row>
  );
};
